


































import Vue, { PropType, VueConstructor } from 'vue';
import BookingButtonsModal from '@/components/orderDetailParts/BookingButtonsModal.vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import CustomerOrderService from '@/services/customers/CustomerOrderService';
import ToastMessage from '@/models/ToastMessage';
import Notification from '@/components/shared/Notification.vue';
import { isBefore, parseISO } from 'date-fns';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: {
    BookingButtonsModal,
    Notification,
  },
  mixins: [FormaterMixin],
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  data() {
    return {
      showConfirmationModal: false,
      title: '',
      label: '',
      btnLabel: '',
      cancellationNote: '',
      isWithdrawClick: false,
      showNotification: false,
    };
  },
  computed: {
    popupMessage(): string {
      if (this.isWithdrawClick) {
        return this.$t('components.guestOrderDetailButtons.popupWithdrawMessage', {
          playroomName: this.order.playroomName,
          checkIn: this.formatIsoDateTime(this.order.start),
          checkOut: this.formatIsoDateTime(this.order.end),
        }).toString();
      } else {
        return this.$t('components.guestOrderDetailButtons.popupCancelMessage').toString();
      }
    },
    canBeWithdrawn(): boolean {
      if (this.order.status != 'Pending') {
        return false;
      }
      const startDate = parseISO(this.order.start);
      return isBefore(new Date(), startDate);
    },
    canBeCancelled(): boolean {
      if (this.order.status != 'Confirmed') {
        return false;
      }
      const startDate = parseISO(this.order.start);
      return isBefore(new Date(), startDate);
    },
  },
  methods: {
    handleWithdraw() {
      this.title = this.$t('components.guestOrderDetailButtons.titleWithdraw').toString();
      this.label = this.$t('components.guestOrderDetailButtons.label', {
        hostName: this.order.ownerName,
      }).toString();
      this.btnLabel = this.$t('components.guestOrderDetailButtons.withdraw').toString();
      this.cancellationNote = this.$t('components.guestOrderDetailButtons.cancellationNoteWithdraw').toString();

      this.isWithdrawClick = true;
      this.showConfirmationModal = true;
    },
    handleCancel() {
      this.title = this.$t('components.guestOrderDetailButtons.titleCancel').toString();
      this.label = this.$t('components.guestOrderDetailButtons.label', {
        hostName: this.order.ownerName,
      }).toString();
      this.btnLabel = this.$t('components.guestOrderDetailButtons.cancel').toString();

      if (this.order.cancellation.percentage === 0) {
        this.cancellationNote = this.$t('components.guestOrderDetailButtons.cancellationNoteFree').toString();
      } else {
        this.cancellationNote = this.$t('components.guestOrderDetailButtons.cancellationNotePer', {
          cancelationFee: this.order.cancellation.percentage,
        }).toString();
      }

      this.isWithdrawClick = false;
      this.showConfirmationModal = true;
    },
    onUpdateShow(show: boolean, message: string) {
      if (this.isWithdrawClick) {
        CustomerOrderService.withdrawOrder(this.order.orderId, message).then(
          () => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('components.hostOrderDetailButtons.successMsg').toString(), 'bg-success')
            );
            this.showConfirmationModal = show;
            this.showNotification = true;
          },
          (error) => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(
                this.$t('components.hostOrderDetailButtons.errorMsg').toString() + error.message,
                'bg-warning'
              )
            );
          }
        );
      } else {
        CustomerOrderService.cancelOrder(this.order.orderId, message).then(
          () => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('components.hostOrderDetailButtons.successMsg').toString(), 'bg-success')
            );
            this.showConfirmationModal = show;
            this.showNotification = true;
          },
          (error) => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(
                this.$t('components.hostOrderDetailButtons.errorMsg').toString() + error.message,
                'bg-warning'
              )
            );
          }
        );
      }
    },
    onCloseModal() {
      this.showConfirmationModal = false;
    },
    handleCloseNotification() {
      this.showNotification = false;
      this.$router.push({ name: 'CustomerOrderIndex' });
    },
  },
});
