















import Vue from 'vue';
import CustomerOrderService from '@/services/customers/CustomerOrderService';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import OrderDetailGeneralInformationPart from '@/components/orderDetailParts/OrderDetailGeneralInformationPart.vue';
import OrderDetailPricePart from '@/components/orderDetailParts/OrderDetailPricePart.vue';
import OrderDetailImportantInformationPart from '@/components/orderDetailParts/OrderDetailImportantInformationPart.vue';
import OrderDetailTitlePart from '@/components/orderDetailParts/CustomerOrderDetailTitlePart.vue';
import OrderDetailEventsPart from '@/components/orderDetailParts/OrderDetailEventsPart.vue';
import GuestOrderDetailButtons from '@/components/orderDetailParts/GuestOrderDetailButtons.vue';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  components: {
    OrderDetailGeneralInformationPart,
    OrderDetailPricePart,
    OrderDetailImportantInformationPart,
    OrderDetailTitlePart,
    OrderDetailEventsPart,
    GuestOrderDetailButtons,
  },
  props: {
    orderId: {
      type: String,
    },
  },
  data() {
    return {
      order: new CustomerOrderDetailDto(),
      ready: false,
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.guest.GuestOrderDetail.meta.title', { playroomName: this.order.playroomName }).toString(),
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { to: { name: 'CustomerOrderIndex' }, translationKey: 'pages.guest.GuestOrderDetail.breadcrumb.orders' },
        { text: this.order.playroomName },
      ]);
    },

    loadData() {
      CustomerOrderService.getDetail(this.orderId).then((res) => {
        this.ready = true;
        this.order = res.value;
        this.setBreadCrumb();
      });
    },
  },
});
